<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  mounted() {
    this.$debug.info('mounted commission statement entry')
    let route = null
    if (this.currentUser?.related?.agency?.data?.attributes?.has_aggregator) {
      route = 'commission-statement.aggregator'
    } else {
      route = 'commission-statement.unmatched'
    }

    this.$router.push({
      name: route,
      params: {
        month: this.$route.params.month,
        year: this.$route.params.year,
      },
    })
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
  },
}
</script>

<style></style>
